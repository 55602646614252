/* ---------------------------------
StyledLink
--------------------------------- */

import React from "react";
import styled from "@emotion/styled";
import { Link } from "@chakra-ui/react";
import { ThemeOverride } from "@chakra-ui/react";
import { LinkProps } from "@chakra-ui/layout/dist/types/link";

type StyledLinkVariants = "regular" | "faq";

type OwnProps = { variant?: StyledLinkVariants } & Record<string, unknown>;

export const StyledLink = styled(Link)<LinkProps & OwnProps>`
  text-decoration: underline;
  font-weight: ${({ variant }) => (variant === "faq" ? "normal" : "bold")};
  color: ${({
    variant,
    theme,
  }: {
    variant: StyledLinkVariants;
    theme: ThemeOverride;
  }) => (variant === "faq" ? theme?.colors?.accent?.["01"] : "inherit")};
  padding: 2px 4px;
  border-radius: 2px;

  &:hover {
    background-color: ${({ theme }: { theme: ThemeOverride }) =>
      theme?.colors?.tim?.["50"]};
  }
`;
